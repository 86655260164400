import React, { ReactElement } from 'react'
import styled from 'styled-components'
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs'
import { Link } from 'react-router-dom'
import { colors, IOrganisationResponse } from '@one-tree/library'
import { itemWord, shortVoucherWord } from '../../helpers/FormatHelper'
import { useOrganisation } from '../../context/OrganisationProvider'
import { capitalize } from '../../helpers/DataTransformer'

const StyledDiv = styled.div`
  color: ${colors.darkerGray};
  position: absolute;
  top: 6px;
  left: 8px;

  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    font-size: 0.9rem;
    transition: 0.2s;
    :hover {
      color: ${colors.black};
    }
  }
`

const getBreadcrumbs = (
  breadcrumbs: BreadcrumbData[],
  showThreshold: number,
  organisation: IOrganisationResponse,
): ReactElement[] | false => showThreshold < breadcrumbs.length
  && breadcrumbs.map((breadcrumb: BreadcrumbData, index: number) => {
    // TODO: types
    // @ts-ignore
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    let breadcrumbText = (breadcrumb.breadcrumb as ReactElement).props.children

    if (breadcrumbText === 'Items') {
      breadcrumbText = `${capitalize(itemWord(organisation))}s`
    }

    if (breadcrumbText === 'Template') {
      breadcrumbText = `${capitalize(shortVoucherWord(organisation))} template`
    }

    return (
      <span key={breadcrumb.key}>
        <Link to={breadcrumb.key}>{breadcrumbText.replace('-', ' ')}</Link>
        {breadcrumbs.length - 1 !== index && ' \u203a '}
      </span>
    )
  })

function Breadcrumbs(): ReactElement {
  const { organisation } = useOrganisation()
  const breadcrumbs = useBreadcrumbs()

  const renderBreadcrumbs = organisation && getBreadcrumbs(breadcrumbs, 1, organisation)

  return <StyledDiv>{renderBreadcrumbs}</StyledDiv>
}
export default Breadcrumbs
