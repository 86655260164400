import React, { ReactElement } from 'react'
import {
  FiHome,
  FiTrendingUp,
  FiFileText,
  FiGift,
  FiPieChart,
  FiBook,
  FiHelpCircle,
  FiMessageCircle,
  FiUsers,
  FiInbox,
  FiMonitor,
  FiGrid,
  FiLogOut,
  FiPhone,
  FiEdit,
} from 'react-icons/fi'
import {
  colors, hasRole, LinkSection, MenuLink, Role,
} from '@one-tree/library'
import { RoutePath } from '../types/Routes'
import { itemWord, shortVoucherWord } from '../helpers/FormatHelper'
import { capitalize } from '../helpers/DataTransformer'
import { useOrganisation } from '../context/OrganisationProvider'

function SideMenu(): ReactElement {
  const { organisation, organisationUser } = useOrganisation()
  const { role } = organisationUser || {}

  const highlight = {
    primaryColor: colors.blue,
    highlightColor: colors.lightestBlue,
  }

  return (
    <>
      <LinkSection>
        <MenuLink
          to={RoutePath.Home}
          icon={<FiHome />}
          label="Home"
          hasPermission={hasRole(role, Role.OrganisationRedeemer)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Sales}
          icon={<FiTrendingUp />}
          label="Sales"
          hasPermission={hasRole(role, Role.OrganisationUser)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.ManualOrder}
          icon={<FiEdit />}
          label="Manual order"
          hasPermission={hasRole(role, Role.OrganisationUser)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Invoices}
          icon={<FiFileText />}
          label="Invoices"
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Manage}
          icon={<FiGift />}
          label={`Manage ${itemWord(organisation)}s`}
          notExact={true}
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Reporting}
          icon={<FiPieChart />}
          label="Reporting"
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
      </LinkSection>
      <LinkSection>
        <MenuLink
          to="https://www.one-tree.net/user-guides/"
          icon={<FiHelpCircle />}
          label="User guide"
          externalLink={true}
          hasPermission={hasRole(role, Role.OrganisationRedeemer)}
          {...highlight}
        />
        <MenuLink
          to="https://www.one-tree.net/promotional-materials/"
          icon={<FiBook />}
          label="Marketing"
          externalLink={true}
          hasPermission={hasRole(role, Role.OrganisationUser)}
          {...highlight}
        />
        <MenuLink
          to="mailto:support@one-tree.net?subject=One%20Tree%20Portal%20-%20Contact%20us"
          icon={<FiMessageCircle />}
          label="Contact us"
          externalLink={true}
          hasPermission={hasRole(role, Role.OrganisationRedeemer)}
          {...highlight}
        />
      </LinkSection>
      <LinkSection style={{ marginTop: 'auto' }}>
        <MenuLink
          to={RoutePath.Users}
          icon={<FiUsers />}
          label="Manage users"
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Contacts}
          icon={<FiPhone />}
          label="Manage contacts"
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Billing}
          icon={<FiInbox />}
          label="Billing"
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.WebShop}
          icon={<FiMonitor />}
          label="Web shop"
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
        <MenuLink
          to={RoutePath.Template}
          icon={<FiGrid />}
          label={`${capitalize(shortVoucherWord(organisation))} template`}
          hasPermission={hasRole(role, Role.OrganisationAdmin)}
          {...highlight}
        />
      </LinkSection>
      <LinkSection>
        <MenuLink
          to={RoutePath.Logout}
          icon={<FiLogOut style={{ transform: 'rotate(180deg)' }} />}
          label="Logout"
          hasPermission={true}
          {...highlight}
        />
      </LinkSection>
    </>
  )
}
export default SideMenu
